// src/components/Layout.js
import React from 'react';
import Navbar from "./navbar";
import Footer from "./footer";
import styled from "styled-components";
import {Helmet} from "react-helmet";

const Main = styled.main`
    margin-top: 124px;
    padding-left: 16px;
    padding-right: 16px;
`

const Layout = ({children}) => {
    const title = 'DuckDuckLink';
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content="The most advanced VPN. And much more."/>
                <meta name="keywords" content="China VPN, VPN, duckducklink, Hong Kong VPN, Japan VPN"/>
                <meta property="og:title" content={title}/>
                <meta property="og:description" content="The most advanced VPN. And much more."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://duckdcuklink.com"/>
                <meta property="og:image" content="https://duckdcuklink.com/image.jpg"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:description" content="The most advanced VPN. And much more."/>
                <meta name="twitter:image" content="https://duckdcuklink.com/image.jpg"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="icon" href="/images/favicon.svg"/>
                <link rel="mask-icon" href="/images/favicon.svg"/>
                <link rel="apple-touch-icon" href="/images/apple-touch-icon.png"/>
                <meta name="theme-color" content="#000000"/>
                <meta charSet="utf-8"/>
                <html lang="en"/>
                <script type="application/ld+json">
                    {`
                {
                  "@context": "https://schema.org",
                  "@type": "WebSite",
                  "name": "${title}",
                  "url": "https://duckducklink.com"
                }
                `}
                </script>
            </Helmet>
            <Navbar/>
            <Main>{children}</Main>
            <Footer/>
        </>
    );
};

export default Layout;