exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-manage-subscription-tsx": () => import("./../../../src/pages/manage-subscription.tsx" /* webpackChunkName: "component---src-pages-manage-subscription-tsx" */),
  "component---src-pages-payment-result-tsx": () => import("./../../../src/pages/payment-result.tsx" /* webpackChunkName: "component---src-pages-payment-result-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-servers-tsx": () => import("./../../../src/pages/servers.tsx" /* webpackChunkName: "component---src-pages-servers-tsx" */)
}

